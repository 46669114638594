<template lang="html">
  <div v-if="!isMobile" class="page-info-back">
    <Back
      v-if="globalSettings"
      color="#0062ae"
      :label="globalSettings['button_names']['back_button']"
    />
  </div>
  <div class="page-info" v-if="initialData && isMobile">
    <div v-if="isMobile" class="page-info__back">
      <Back label="" color="#C99D66" />
    </div>
    <div class="page-info__image" v-if="initialData.image">
      <img
        :src="initialData.image.sizes['medium_large']"
        :alt="initialData.image.alt"
      />
    </div>
    <div class="page-info__container">
      <div class="page-info__heading" v-if="initialData.heading">
        <Heading :text="initialData.heading" :hType="1" />
      </div>
      <div
        class="page-info__description"
        v-if="initialData.text_after_heading"
        v-html="initialData.text_after_heading"
      ></div>
      <div
        class="page-info__description"
        v-if="initialData.description"
        v-html="initialData.description"
      ></div>
      <div class="page-info__button" v-if="initialData['button']">
        <Button backgroundColor="#C99D66">
          <router-link :to="initialData['button']['url']">{{
            initialData['button']['title']
          }}</router-link>
        </Button>
      </div>
    </div>
  </div>
  <div
    class="gray-background page-info page-info--desktop"
    v-if="initialData && !isMobile"
  >
    <div class="row">
      <div class="left">
        <div class="page-info__image" v-if="initialData.image">
          <div>
            <img
              :src="initialData.image.sizes['medium_large']"
              :alt="initialData.image.alt"
            />
          </div>
        </div>
      </div>
      <div class="right">
        <div class="page-info__container">
          <div class="page-info__heading" v-if="initialData.heading">
            <Heading :text="initialData.heading" :hType="1" />
          </div>
          <div
            class="page-info__description"
            v-if="initialData.text_after_heading"
            v-html="initialData.text_after_heading"
          ></div>
        </div>
        <div class="page-info__container">
          <div
            class="page-info__description"
            v-if="initialData.description"
            v-html="initialData.description"
          ></div>
          <div class="page-info__button" v-if="initialData['button']">
            <Button backgroundColor="#C99D66">
              <router-link :to="initialData['button']['url']">{{
                initialData['button']['title']
              }}</router-link>
            </Button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/Button/Button';
import Heading from '@/components/Heading/Heading';
import Back from '@/components/Back/Back';

export default {
  name: 'BackButton',
  components: { Back, Heading, Button },
  props: {
    initialData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isMobile() {
      return this.$store.state.global.isMobile;
    },
    globalSettings() {
      return this.$store.state.global.settings;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'styles';

@media (max-width: 768px) {
  :deep(.heading) {
    h1 {
      font-size: 35px !important;
      text-align: center;
    }
  }
}
</style>
